import Fetcher from './fetcher.js';

// const defaultJSON = {
// 	'currentArtist': null,
// 	'currentArtistID': null,
// 	'currentArtists': [],
// 	'currentAlbum': null,
// 	'currentAlbumID': null,
// 	'currentAlbums': [],
// 	'currentTrack': null,
// 	'currentTrackID': null,
// 	'currentTracks': [],
// 	'history': {},
// }

// function setDefaultStored() {
// 	return localStorage.setItem('randomizer_app_storage', JSON.parse(defaultJSON));
// }

// function getStored(key = '') {
// 	let stored = localStorage.getItem('randomizer_app_storage');
// 	if (!stored) {
// 		setDefaultStored();
// 		return defaultJSON[key];
// 	}
// 	stored = JSON.parse(stored);
// 	return stored[key];
// }

// function setStored(key = '', value = null) {
// 	if (!key || !value) return;

// 	let stored = localStorage.getItem('randomizer_app_storage');
// 	if (!stored) {
// 		setDefaultStored();
// 		return defaultJSON[key];
// 	}
// 	stored = JSON.parse(stored);
// 	stored[key] = value;
// 	return;
// }

// Random behavior expeted by user is not truly random
// so we must check the history to see not repeat rerolled items
function randomize(max, asset = 'albums', assetID = null) {

	if (!assetID) return;

	if (max < 2) return 0;

	const ix = Math.floor(Math.random() * max);

	return ix;
}


async function getArtists(querysearch = '', limit = 5) {
	// Fetch a list of the top likely artists in search
	const data = await Fetcher({
		querysearch,
		endpoint: 'search',
		limit
	});
	if (!('items' in data)) {
		return null;
	}
	return data.items;
}

async function getAlbums(artistID = null) {
	if (!artistID) return;

	// get alums from artist
	const albumData = await Fetcher({
		id: artistID,
		endpoint: 'albums'
	});

	if (!albumData) {
		return null;
	}

	return albumData.items;
}

async function getSongs(albumID = null) {
	if (!albumID) return;

	const songData = await Fetcher({
		id: albumID,
		endpoint: 'tracks',
	});

	if (!songData) {
		return null;
	}

	return songData.items;
}

// async function getAssets(artistID = null) {
// 	if (!artistID) return;

// 	// get alums from artist
// 	const albumData = await Fetcher({
// 		id: artistID,
// 		endpoint: 'albums'
// 	});

// 	if (!albumData) {
// 		return null;
// 	}

// 	// get random album
// 	const totalAlbums = 'items' in albumData ? albumData.items.length : 0;
// 	const album = albumData.items[randomize(totalAlbums, 'albums', artistID)];
// 	const albumID = album.id;

// 	let results = {
// 		albums: albumData.items,
// 		totalAlbums,
// 		album,
// 		albumID,
// 	}

// 	const songData = await Fetcher({
// 		id: albumID,
// 		endpoint: 'tracks',
// 		limit: 8
// 	});

// 	if (!songData) {
// 		return null;
// 	}

// 	const totalSongs = 'items' in songData ? songData.items.length : 0;
// 	const song = songData.items[randomize(totalSongs, 'songs', albumID)];

// 	results['songs'] = songData.items;
// 	results['song'] = song;
// 	results['songID'] = song.id;

// 	return results;
// }


export {
	randomize,
	getArtists,
	getAlbums,
	getSongs,
	// getAssets
}