import { useState } from 'react';
import './Unsorted.css';

import { getAlbums, getArtists, getSongs, randomize } from './controllers';

function Unsorted() {

	// Page
	const [page, setPage] = useState('main');
	const [loading, setLoading] = useState(false);
	const [feedback, setFeedback] = useState('');
	const [querysearch, setQuery] = useState('');
	const [show, setShow] = useState('');
	const [history, setHistory] = useState([]);
	// const [recents, setRecents] = useState([]);

	// Assets
	const [songs, setSongs] = useState([]);
	const [albums, setAlbums] = useState([]);
	const [artists, setArtists] = useState([]);

	const [song, setSong] = useState({});
	const [album, setAlbum] = useState({});
	const [artist, setArtist] = useState({});

	// Searhes for an artist
	async function search(query) {
		setLoading(true);
		setSong({});
		setAlbum({});
		setArtist({});

		// fetches from api
		const artists = await getArtists(query);
		if (!artists) {
			showFeedback('Sorry, no artists with this name were found.\nCheck if name is correct or try again later.');
			return setLoading(false);
		}
		setArtists(artists);
		setShow('artists');
		setLoading(false);
	}

	// Selects the artist after the querysearch
	async function pickArtist(artist) {
		setArtist(artist);

		setLoading(true);
		const albums = await getAlbums(artist.id);
		if (!albums) {
			showFeedback('Sorry, the albums of this artist couldn\'t be found.\nTry again later or reload the page');
			return setLoading(false);
		}

		// Stops showing list of artists
		setAlbums(albums);
		setShow('albums');
		setLoading(false);
	}

	// Selects album after picking artist
	async function pickAlbum(album) {
		setAlbum(album);
		setHistory([]);

		setLoading(true);
		const songs = await getSongs(album.id);

		if (!songs) {
			showFeedback('Sorry, the assets of this artist couldn\'t be found.\nTry again later or reload the page');
			return setLoading(false);
		}

		setSongs(songs);

		const totalSongs = songs.length;
		let song = songs[randomize(totalSongs, 'songs', album.id)];

		if (!song) {
			return showFeedback(`Oops, there's been error. Please try reloading the site.`);
		}

		setSong(song);
		setShow('result');
		setLoading(false);
	}

	function showFeedback(message) {
		setFeedback(message);
		setTimeout(() => {
			setFeedback('');
		}, 3000)
	}

	function cancel() {
		setQuery('');
		setLoading(false);
		setArtists([]);
	}


	function randomTrack() {
		setHistory([...history, song.id]);
		const totalSongs = songs.length;
		let newSong = songs[randomize(totalSongs, 'songs', album.id)];

		if (!newSong) {
			return showFeedback(`Oops, there's been error. Please try reloading the site.`);
		}

		// If we already went through all the song we skip the loop and find a different album
		if (history.length === songs.length) {
			setHistory([]);
			return randomAlbum();
		}

		// Randomize but don't repeat a song
		while (history.includes(newSong.id)) {
			newSong = songs[randomize(totalSongs, 'songs', album.id)];
			break;
		}



		setSong(newSong);
	}

	async function randomAlbum() {
		setLoading(true);
		setHistory([]);
		const totalAlbums = albums.length;
		const album = albums[randomize(totalAlbums, 'albums', artist.id)];
		const albumID = album.id;

		setAlbum(album);

		const songs = await getSongs(album.id);
		if (!songs) {
			showFeedback('Sorry, no songs for this artist were found.\nTry again later or try reloading the page');
			return setLoading(false);
		}
		setSongs(songs);
		const totalSongs = songs.length;
		const song = songs[randomize(totalSongs, 'songs', albumID)];

		if (!song) {
			return showFeedback(`Oops, there's been error. Please try reloading the site.`);
		}

		setSong(song);
		setLoading(false);
	}

	function copy() {
		navigator.clipboard.writeText(song.external_urls.spotify);
		showFeedback('Link copied!');
	}

	const isMobile = !!(window.screen.width < 500);

	return (
		<>
			<span className='icon random-spa-logo' onClick={() => setPage('main')}>
				youtube_searched_for
			</span>
			<>
				<div className={`random-spa-container ${page !== 'main' ? 'no-main' : ''}`}>
					{page === 'main' &&
						(
							<>
								<div className='random-spa-flex' />
								<div className='random-spa-header'>
									<div className='random-spa-header-text'>
										<div className='random-spa-title'>unsorted{isMobile ? '_' : '.'}</div>
										<div className='random-spa-subtitle'>a spotify randomizer</div>
									</div>

									<div className='input-container'>
										<div className='input-control'>
											<input
												className='random-spa-input'
												type='text'
												value={querysearch}
												placeholder='Search for an artist'
												onKeyDown={({ key }) => { if (key === 'Enter') { search(querysearch) } }}
												onChange={({ target }) => setQuery(target.value)}
											/>
											{artists.length > 0 && querysearch !== '' ?
												<span className='input-control-clear icon' onClick={cancel}>close</span>
												:
												<span
													className='input-control-search icon'
													style={!querysearch ? { 'opacity': 0.6, 'cursor': 'auto' } : {}}
													onClick={querysearch ? () => search(querysearch) : null}
												>
													search
												</span>
											}
										</div>
									</div>
								</div>
								<div className='random-spa-flex'>
									<div className='random-spa-header-button' onClick={querysearch ? () => search(querysearch) : null}>
										get random!
									</div>

									{/* {recents.length > 0 &&
										<div className='random-spa-result-recents' >
											Recent searches:
											<div className='random-spa-result-container'>
												{recents.map((nm) => (
													<div className='random-spa-result-name' onClick={() => search(nm)}>
														{nm}
														<span
															className='random-spa-result-icon icon'
															onClick={() => setRecents(recents.filter(n => n !== nm))}
														>close</span>
													</div>
												))}
											</div>
										</div>
									} */}
								</div>
							</>
						)}

					{
						page === 'privacy' &&
						<>
							<div className='random-spa-block-text'>
								Your data is not collected at any point, which also means your previous searches will not be cached or saved.
							</div>
							<div className='random-spa-block-text'>
								For now, no caching is available.
							</div>
						</>
					}
					{
						page === 'about' &&
						<>
							<div className='random-spa-block-text'>
								This small project was developed as a way to showcase API integration with the Spotify API on a single page application.</div>

							<div className='random-spa-block-text'>this is only the first version of the code.</div>

						</>
					}

				</div>
				<div className='random-spa-body' style={isMobile ? { height: page === 'main' ? '60%' : '5%' } : { width: page === 'main' ? '50%' : '5%' }}>
					{page === 'main' &&
						(
							loading ?
								<div className='random-spa-loading'>
									< span className='random-spa-loading-icon icon'>progress_activity</span>
									<div className='random-spa-loading-text'>Loading . . . </div>
								</div>
								: (
									<>
										{show === 'artists' &&
											<div className='random-spa-artists-container'>
												{artists.map((art, index) => {
													const img = art.images.length > 0 ?
														<img src={art.images[0].url} alt={art.name} /> : <div className='default-no-image'><span className='icon'>artist</span></div>;
													return (
														<div
															key={art.id}
															className={`random-spa-artist-container` + (index === 0 ? ' random-spa-artist-best-result' : '')}
															onClick={() => pickArtist(art)}
														>
															{img}
															<div className='random-spa-artist-name'>{art.name}</div>
														</div>
													);
												})}
											</div>}


										{show === 'albums' &&
											<>
												<span className='icon return-button' onClick={() => setShow('artists')}>undo</span>
												<div className='random-spa-albums-container'>
													{albums.map((alb) => {
														const img = alb.images.length > 0 ?
															<img src={alb.images[0].url} alt={alb.name} /> : <div className='default-no-image'><span className='icon'>library_music</span></div>;

														return (
															<div
																key={alb.id}
																className='random-spa-album-container'
																onClick={() => pickAlbum(alb)}
															>
																{img}
																<div className='random-spa-album-name'>{alb.name}</div>
															</div>
														)
													})}
												</div>
											</>
										}


										{show === 'result' &&
											<>
												<span className='icon return-button' onClick={() => setShow('albums')}>undo</span>

												<div className='random-spa-results'>
													<img src={album.images[0].url} alt='' className='random-spa-album' />
													<div className='random-spa-results-info'>
														<div className='random-spa-results-track'>{song.name}</div>
														<div className='random-spa-results-album'>{album.name}</div>
														<div className='random-spa-results-artist'>{artist.name}</div>
														<div className='random-spa-buttons-container'>
															<div
																className={`random-spa-button ${songs.length === 1 ? 'disabled' : ''}`}
																onClick={songs.length === 1 ? () => { } : () => randomTrack()}
															>
																<span className='icon'>refresh</span>
																<div className='random-spa-button-text'> {`${!isMobile ? 'New' : ''}`} Track</div>
															</div>
															<div className='random-spa-button' onClick={randomAlbum}>
																<span className='icon'>refresh</span>
																<div className='random-spa-button-text'> {`${!isMobile ? 'New' : ''}`} Album</div>
															</div>
															<div className='random-spa-button' onClick={copy}>
																<span className='icon'>share</span>
															</div>
														</div>
													</div>
												</div>
											</>
										}

									</>
								)
						)
					}
				</div>
			</>

			<div className='random-spa-nav'>
				<div onClick={() => setPage('privacy')} style={{ color: page === 'privacy' ? '#fff' : '#7ed857' }}>privacy</div>
				<div onClick={() => setPage('about')} style={{ color: page === 'about' ? '#fff' : '#7ed857' }}>about the code</div>
				<div onClick={() => window.open('https://github.com/juliagato/unsorted-public')}>github</div>

			</div>

			{
				feedback && ( // Snackbar for feedback
					<div className='random-spa-feedback'>
						<div className='random-spa-feedback-text'>{feedback}</div>
						<span className='icon' onClick={() => setFeedback('')}>close</span>
					</div>
				)
			}
		</>
	);
}

export default Unsorted;
